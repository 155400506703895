.main_header {
    .header_bar {
        background-color: $color-palette-1;

        p {
            font-size: 0.875em;
            color: $font-color;
        }

        i {
            font-size: 1.6em;
            color: $font-color;
        }
    }
}

.navbar-brand {
    h1 {
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 600;
        font-style: normal;
        color: $color-front-default;

        @media (max-width: 1000px) {
            font-size: 1.5rem;
        }

        @media (max-width: 528px) {
            display: none;
        }
    }

    .brand-image-custom {
        width: 150px;
        height: auto !important;
    }

    @media (min-width: 354px) and (max-width: 465px) {
        .brand-image-custom {
            width: 100px;
            height: auto !important;
        }
    }

    @media (min-width: 304px) and (max-width: 353px) {
        .brand-image-custom {
            width: 80px;
            height: auto !important;
        }
    }

    @media (max-width: 303px) {
        .brand-image-custom {
            width: 50px;
            height: auto !important;
        }
    }
}
