.main_property {
    h2 {
        margin: 40px 0 20px 0;
    }

    .main_property_price {
        p {
            color: $color-front-support;
        }
        .main_property_price_small {
            margin-bottom: 0px;
            font-size: 0.8em;
        }

        .main_property_price_big {
            margin-bottom: 0px;
            font-size: 1.4em;
        }
    }

    .main_property_content_description {
        p {
            margin-bottom: 20px;
            color: $color-palette-1;
        }
    }

    .main_property_structure {
        .main_property_structure_item {
            padding: 6px 12px;
            margin-left: 10px;
            margin-bottom: 10px;
            background-color: $color-palette-4;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            color: $color-palette-1;
        }
    }

    .main_property_contact {
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        overflow: hidden;

        h2 {
            font-size: 1.2em;
            margin: 0px;
            padding: 20px;
            color: $color-front-opposite;
        }

        form {
            padding: 20px;
            background-color: #f9f9f9;
            border: 1px solid #ccc;
        }
    }
}

.main_property_share {
    padding: 1em 0;
    text-align: right;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .btn-front {
        background-color: $color-front-default;
        border-color: $color-front-default;
        color: #fff;

        &:hover {
            background-color: $color-palette-13;
        }
    }
}

@media (max-width: 370px) {
    .main_property_share {
        display: none;
    }
}
