.main_slide {
    height: 532px;
    flex-basis: 60%;
    position: relative;
    background: linear-gradient(
        to right,
        $color-palette-3 0%,
        transparent 100%
    );
    animation: pulse 2.5s forwards;

    @keyframes pulse {
        0% {
            background-color: $color-palette-4;
        }
        100% {
            background-color: transparent;
        }
    }

    &:after {
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
        background: url("../../img/home-hero.webp") center/cover no-repeat;
        background-size: 100%;
        background-position: center 50%;
    }

    .main_slide_content {
        padding: 30px 0;
        text-transform: uppercase;
        font-size: 2.5em;
        font-weight: normal;
        padding: 30px;
        background-color: rgba(31, 32, 31, 0.8);
        color: #ccc;

        span {
            font-weight: bold;
            color: #fff;
        }
    }
}

.main_list_group {
    .main_list_group_title {
        position: relative;
    }

    .main_list_group_title::after {
        content: "";
        bottom: 0px;
        left: calc(50% - 210px);
        width: 420px;
        max-width: 100%;
        height: 4px;
        background: $color-front-default;
        position: absolute;
        border-radius: 4px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
    }

    .main_list_group_item {
        .main_list_group_items_item {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            a {
                flex-basis: 100%;
                min-height: 200px;
                color: $font-color;
                transition: 0.2s;
                font-size: 1.2em;
                text-shadow: 0px 3px 5px #333;

                &:hover {
                    text-decoration: none;
                    font-size: 1.25em;
                }

                div {
                    flex-basis: 100%;
                    min-height: 200px;

                    h2 {
                        font-size: 1em;
                        background-image: linear-gradient(
                            to right,
                            $color-palette-1 0%,
                            transparent 100%
                        );
                        width: 100%;
                        padding: 1.5rem;
                    }
                }
            }
        }
    }
}

.main_properties_title {
    position: relative;
}

.main_properties_title::after {
    content: "";
    bottom: -20px;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 4px;
    background: $color-front-support;
    position: absolute;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
}

@media (max-width: 420px) {
    .main_list_group_title::after {
        width: 100% !important;
        left: 0 !important;
    }
}
