$color-front-default: #17344e;
$color-front-opposite: #fbfbfb;
$color-front-support: #707176;

$color-palette-1: #17344e;
$color-palette-12: #006086;
$color-palette-13: #007c9f;
$color-palette-2: #fab61b;
$color-palette-21: #f99b1c;
$color-palette-3: #51748b;
$color-palette-4: #a6bcc4;
$color-palette-5: #1f201f;
$color-palette-6: #707176;

$font-color: #fbfbfb;
$color-title: #51748b;
