.smoothScroll-top {
    position: fixed;
    opacity: 0;
    margin: none;
    border: none;
    visibility: hidden;
    overflow: hidden;
    text-align: center;
    z-index: 999;
    color: $color-front-opposite;
    background-color: $color-palette-1;
    width: 47px;
    height: 44px;
    line-height: 22px;
    right: 25px;
    bottom: -25px;
    padding-top: 2px;
    font-size: 1.5rem;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    box-shadow: inset 0 -5px 0 0 $color-palette-2;

    i {
        margin-bottom: 10px;
    }

    &.show {
        visibility: visible;
        cursor: pointer;
        opacity: 1;
        bottom: 25px;
    }
}