.main_contact {
    form {
        padding: 20px;
        width: 600px;
        max-width: calc(100% - 40px);
        background: #fff;
        margin: 0 auto;
        margin-top: 40px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;

        h2 {
            margin-bottom: 20px;
            font-size: 1.2em;
        }
    }
}

.main_contact_types {
    h2 {
        font-size: 1.4em;
        color: $color-front-support;
    }

    p {
        font-size: 0.875em;
    }
}
